import { ScreenText } from '@/lang/ScreenText';
import { PriceResponse, SessionBillingCycle } from '@/Model/payments/types';
import { Session, SessionWithExistingSiteLocation } from '@/Model/sessions/types';


export function findSessionPrice(session: Session | SessionWithExistingSiteLocation): PriceResponse | undefined {
  if (session.defaultPriceId && session.prices) {
    return session.prices.find(p => p.priceId === session.defaultPriceId);
  }
  return undefined;
}

export function getSessionBillingCycleScreenTextMap(screenText: ScreenText): Map<SessionBillingCycle, string> {
  return new Map<SessionBillingCycle, string>([
    [SessionBillingCycle.OneTime, screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_FREQUENCY_INTERVAL_DEFAULT')],
    [SessionBillingCycle.Weekly, screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_FREQUENCY_INTERVAL_WEEKLY')],
    [SessionBillingCycle.Monthly, screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_FREQUENCY_INTERVAL_MONTHLY')],
  ]);
}