



































































































































































































import { ScreenText } from '@/lang/ScreenText';
import { DayAbbreviation } from '@/Model/selectedDays/types';
import { Session, SessionCardPromoCodeListItem } from '@/Model/sessions/types';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ContextMenu from '@/commoncomponents/contextMenuComponents/ContextMenu.vue';
import ContextMenuItem from '@/commoncomponents/contextMenuComponents/ContextMenuItem.vue';
import { CouponDuration, DiscountType, AmountUnitType, EarlyRegistrationRule, MultiParticipantRule, PriceResponse, PromoCode, SessionBillingCycle } from '@/Model/payments/types';
import { findSessionPrice, getSessionBillingCycleScreenTextMap } from '@/services/sessions/helpers';
import { formatCurrency } from '@/services/common/formatters';
import { getFormattedFeeDisplayValue } from '@/services/payments/helpers';
import { Status } from '@/Model/shared/types';
import APP_CONST from '@/constants/AppConst';

@Component({
  components: {
    ContextMenu,
    ContextMenuItem
  }
})
export default class SessionCard extends Vue {
  @Prop({ type: Object, required: true }) readonly session!: Session;

  readonly screenText = new ScreenText();
  private readonly sessionBillingCycleScreenTextMap: Map<SessionBillingCycle, string> = getSessionBillingCycleScreenTextMap(this.screenText);
  isAutomaticDiscountEnabled = false;

  emitEdit() {
    this.$emit('editSession');
  }

  async created() {
    const automaticDiscountFlag = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.automaticDiscounts);

    this.isAutomaticDiscountEnabled = automaticDiscountFlag;

  }

  get daysAbbreviations(): Array<DayAbbreviation> {
    return APP_UTILITIES.getDaysAbbreviations(true);
  }

  get enrollmentBadgeText(): string {
    return `${this.session.enrollmentCount}${this.session.scholarCapacity
      ? '/' + this.session.scholarCapacity
      : ''} ${this.screenText.getScreenText('SESSION_CARD_ENROLLED_COUNT_TEXT')}`;
  }

  get discountsLabel(): string {
    let label = '';
    let earlyRegistrationLabel = '';
    let multiParticipantLabel = '';

    // TODO: enable this when discounts are available

    // if(this.session.discounts && this.session.discounts.length > 0) {
      
    // }
    // const discountSession = this.session.discounts;
    const discountSession = APP_CONST.MOCKED_DISCOUNTS;

    discountSession.filter(discount => {
      if (discount.discountTypeId === DiscountType.EARLY_REGISTRATION) {
        // format early registration discount label to match format from design
        const discountRule = (discount.rules as [EarlyRegistrationRule])[0];
        const discountAmount = APP_UTILITIES.addSymbol(discountRule.amount, discount.amountUnitType === AmountUnitType.FIXED_AMOUNT);
        const discountDate = APP_UTILITIES.earlyRegistrationDateLabel(discountRule);
        earlyRegistrationLabel = `${discount.name}: (Rule) ${discountAmount} off if registering ${discountRule.timeRangeUnit} ${discountDate} before session start date`;
      }
      else if (discount.discountTypeId === DiscountType.MULTI_PARTICIPANT) {
        // format multi participant discount label to match format from design
        const discountRule = discount.rules as MultiParticipantRule[];

        discountRule.map(rule => {
          const discountAmount = APP_UTILITIES.addSymbol(rule.amount, discount.amountUnitType === AmountUnitType.FIXED_AMOUNT);
          const discountParticipants = rule.participants;
          const discountParticipantsText = discountParticipants === 1
            ? 'participant'
            : 'participants';
          const discountParticipantsLabel = `${discountParticipants} ${discountParticipantsText}`;
          multiParticipantLabel = `${discount.name}: (Rule 1) ${discountAmount} off of ${discountParticipantsLabel} | (Rule 2) ${discountAmount} off of ${discountParticipantsLabel}`;
        });      
      }
    });

    // devider is used to separate early registration and multi participant discounts
    // so we need to conditionally add the separator based on if both discounts are present
    label = `${multiParticipantLabel}${earlyRegistrationLabel.length && multiParticipantLabel.length
      ? ' | '
      : ''}${earlyRegistrationLabel}`;

    return label;
  }

  get sessionScheduleTitle(): string {
    return this.screenText.getScreenText('SESSION_CARD_SCHEDULE_SECTION_TITLE');
  }

  get enrollmentTitle(): string {
    return this.screenText.getScreenText('SESSION_CARD_ENROLLMENT_SECTION_TITLE');
  }

  get paymentSettingsTitle(): string {
    return this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_SECTION_TITLE');
  }

  get datesText(): string {
    return this.screenText.getScreenText('SESSION_CARD_DATES_TEXT');
  }

  get startDate(): string {
    return APP_UTILITIES.getFullDate(this.session.startDateTime);
  }

  get endDate(): string {
    return APP_UTILITIES.getFullDate(this.session.endDateTime);
  }

  get daysText(): string {
    return this.screenText.getScreenText('SESSION_CARD_DAYS_TEXT');
  }

  get days(): string {
    const days: Array<string> = [];
    for (const dayAbbr of this.daysAbbreviations) {
      if (this.session.selectedDays.includes(dayAbbr.day)) {
        days.push(dayAbbr.abbreviation);
      }
    }
    return days.join(', ');
  }

  get hoursText(): string {
    return this.screenText.getScreenText('SESSION_CARD_HOURS_TEXT');
  }

  get startTime(): string {
    return APP_UTILITIES.convert12hrFormateTimeFromDate(this.session.startDateTime);
  }

  get endTime(): string {
    return APP_UTILITIES.convert12hrFormateTimeFromDate(this.session.endDateTime);
  }

  get ageRangeText(): string {
    return this.screenText.getScreenText('SESSION_CARD_AGE_RANGE_TEXT');
  }

  get autoEnrollmentText(): string {
    return this.screenText.getScreenText('SESSION_CARD_AUTO_ENROLLMENT_TEXT');
  }

  get autoEnrollmentOnOffText(): string {
    return this.session.autoEnroll
      ? this.screenText.getScreenText('SESSION_CARD_AUTO_ENROLLMENT_ENABLED_TEXT')
      : this.screenText.getScreenText('SESSION_CARD_AUTO_ENROLLMENT_DISABLED_TEXT');
  }

  get sessionPrice(): PriceResponse | undefined {
    return findSessionPrice(this.session);
  }

  get sessionPriceAmountFormatted(): string {
    if (this.sessionPrice) {
      if (this.isSessionPaymentOneTime) {
        return formatCurrency(this.sessionPrice.price);
      }
      else {
        return `${formatCurrency(this.sessionPrice.price)} ${this.screenText.getScreenText('SESSION_CARD_TOTAL_TEXT')}`;
      }
    }
    return '';
  }

  get isSessionFree(): boolean {
    if (this.sessionPrice) {
      return this.sessionPrice.price === 0;
    }
    return true;
  }

  get isSessionPaymentOneTime(): boolean {
    if (this.sessionPrice) {
      return this.sessionPrice.billingCycle === SessionBillingCycle.OneTime;
    }
    return false;
  }

  get sessionPriceLabel(): string {
    if (this.sessionPrice) {
      return this.isSessionPaymentOneTime
        ? this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_ONE_TIME_PAYMENT_TEXT')
        : this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_RECURRING_PAYMENT_TEXT');
    }
    return '';
  }

  get sessionIntervalLabel(): string {
    return this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_INTERVAL_TEXT');
  }

  get sessionIntervalText(): string {
    if (this.sessionPrice) {
      return this.sessionBillingCycleScreenTextMap.get(this.sessionPrice.billingCycle) || '';
    }
    return '';
  }

  get sessionTaxRateLabel(): string {
    return this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_TAX_RATE_TEXT');
  }

  get sessionTaxRateText(): string {
    return this.session.taxRate
      ? `${this.session.taxRate.displayName} ${this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_TAX_RATE_JOINING_TEXT')} ${this.session.taxRate.percentage}%`
      : this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_NOT_APPLICABLE_TEXT');
  }

  get sessionPromoCodesLabel(): string {
    return this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_TEXT');
  }

  private mapPromoCodeItems(promoCode: PromoCode): SessionCardPromoCodeListItem {
    let amountOrPercentOff = '';
    if (promoCode.coupon.amountOff) {
      amountOrPercentOff = formatCurrency(promoCode.coupon.amountOff);
    }
    else if (promoCode.coupon.percentOff) {
      amountOrPercentOff = `${promoCode.coupon.percentOff}%`;
    }
    let displayText = `${promoCode.code}: ${amountOrPercentOff} ${this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_DISCOUNT_OFF_TEXT')}`;
    if (promoCode.coupon.duration === CouponDuration.Once) {
      displayText += ` ${this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_DURATION_ONCE_TEXT')}`;
    }
    else if (promoCode.coupon.duration === CouponDuration.Repeating) {
      const forText = this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_DURATION_FOR_TEXT');
      const monthsText = this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_DURATION_MONTHS_TEXT');
      displayText += ` ${forText} ${promoCode.coupon.durationInMonths} ${monthsText}`;
    }
    else if (promoCode.coupon.duration === CouponDuration.Forever) {
      displayText += ` ${this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_DURATION_FOREVER_TEXT')}`;
    }
    if (promoCode.expiresAt) {
      const date = APP_UTILITIES.dateTimeStringAsUTCDateTime(promoCode.expiresAt);
      const expiresText = this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_PROMO_CODES_REDEEM_BY_TEXT');
      displayText += ` (${expiresText} ${APP_UTILITIES.getFullDate(date)})`;
    }
    return {
      id: promoCode.id,
      displayText
    };
  }

  get sessionPromoCodesList(): Array<SessionCardPromoCodeListItem> {
    return this.session.promoCodes && this.session.promoCodes.length
      ? this.session.promoCodes.map(this.mapPromoCodeItems)
      : [{
        id: 'promo-code-NA',
        displayText: this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_NOT_APPLICABLE_TEXT')
      }];
  }

  get sessionCustomFeeLabel(): string {
    return this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_FEE_LABEL_TEXT');
  }

  get sessionCustomFeeText(): string {
    // As of right now there is only one Fee for a Payment Account, which is shown for all paid Sessions.
    // Defaulting to use the 1st one as it is unknown how multiple Fees may be handled/displayed in the future.
    const customFee = this.session.customFees[0];
    let text = '';
    if (customFee && customFee.status === Status.Active) {
      text = `${customFee.name}: ${getFormattedFeeDisplayValue(customFee)}`;
      if (customFee.taxRate) {
        const taxRateLabelText = this.screenText.getScreenText('LABEL_TAX_RATE');
        const joiningText = this.screenText.getScreenText('SESSION_CARD_PAYMENT_SETTINGS_TAX_RATE_JOINING_TEXT');
        const taxPercentageText = `${customFee.taxRate.percentage}%`;
        text += ` (${taxRateLabelText}: ${customFee.taxRate.displayName} ${joiningText} ${taxPercentageText})`;
      }
    }
    return text;
  }
}

